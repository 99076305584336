import { Content } from 'components/shared/Content';
import { Divider } from 'components/shared/Divider';
import { Icon } from 'components/shared/Icons';
import Image from 'next/image';
import { forwardRef } from 'react';
import { Header, Section } from 'styles/layout';
import { Heading } from 'styles/typography';
import { IArtist } from 'types';
import { pureSort } from 'utils/pureSort';

import {
	Anchor,
	Article,
	Grid,
	Name,
	Picture,
	SocialList,
	Title,
} from './styles';

interface IArtistsProps {
	artists: IArtist[];
}

export const Artists = forwardRef<HTMLDivElement, IArtistsProps>(
	({ artists }, ref) => {
		const data = artists.sort(pureSort('weight'));
		return (
			<Section id="artists" ref={ref}>
				<Content center>
					<Header>
						<Heading>Artistit</Heading>
					</Header>
					<Grid>
						{data.map((artist) => (
							<Article key={artist.name}>
								<Picture>
									<Image
										alt={artist.name}
										layout="fill"
										src={artist.image.src}
										unoptimized
									/>
								</Picture>
								<Title responsive={{ '@initial': 'mobile', '@bp1': 'desktop' }}>
									<Name>{artist.name}</Name>
									{!!artist.social.length && (
										<SocialList>
											{artist.social.map((some) => (
												<li key={some.channel}>
													<Anchor
														href={some.href}
														rel="noopener noreferrer"
														target="_blank"
														title={some.channel.replace(
															/\b[a-zA-Z]/g,
															(match) => match.toUpperCase(),
														)}
													>
														<Icon channel={some.channel} />
													</Anchor>
												</li>
											))}
										</SocialList>
									)}
								</Title>
							</Article>
						))}
					</Grid>
				</Content>
				<Divider />
			</Section>
		);
	},
);

Artists.displayName = 'Artists';
