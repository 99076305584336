import { unHash } from 'utils/unhash';

const OFFSET = 85;
const DURATION = 100;

export const useScrollTo = () => {
	const scrollTo = (id: string) => {
		const element = document.getElementById(unHash(id));
		if (!element) throw new Error(`Cannot find an element with id ${id}`);

		const position = window.pageYOffset;
		const offset =
			window.pageYOffset + element.getBoundingClientRect().top - OFFSET;

		// If element is close to page's bottom then
		// scroll only to some position above the element.
		const destination =
			document.body.scrollHeight - offset < window.innerHeight
				? document.body.scrollHeight - window.innerHeight
				: offset;

		const diff = destination - position;

		// Easings: https://gist.github.com/gre/1650294
		const ease = (t: number) => --t * t * t + 1;

		if (!diff) return;

		let start: number;

		// Bootstrap our animation - it will get called right before next frame shall be rendered.
		window.requestAnimationFrame(function step(timestamp) {
			if (!start) {
				start = timestamp;
			}
			// Elapsed miliseconds since start of scrolling.
			const time = timestamp - start;

			// Get percent of completion in range [0, 1].
			let percent = Math.min(time / DURATION, 1);

			// Apply the easing.
			// It can cause bad-looking slow frames in browser performance tool, so be careful.
			percent = ease(percent);

			window.scrollTo(0, position + diff * percent);

			// Proceed with animation as long as we wanted it to.
			time < DURATION && window.requestAnimationFrame(step);
		});
	};

	return { scrollTo };
};
