import { styled } from 'styles/stitches.config';

export const Container = styled('div', {
	marginBottom: '6rem',
	position: 'relative',
	overflow: 'hidden',
	maxWidth: '100%',

	'&::before': {
		backgroundColor: '$ox1',
		content: '',
		display: 'block',
		inset: 0,
		position: 'absolute',
		zIndex: 1,
	},

	variants: {
		size: {
			desktop: {
				height: 0,
				paddingBottom: '56.25%',
			},
			mobile: {
				height: 'calc(40vh)',
			},
		},
	},
});

export const Video = styled('video', {
	position: 'absolute',

	variants: {
		size: {
			desktop: {
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			},
			mobile: {
				top: '50%',
				left: '50%',
				minWidth: '100%',
				width: 'auto',
				transform: 'translate3d(-50%,-50%,0)',
			},
		},
	},
});

export const Content = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	margin: '0 auto',
	maxWidth: '96%',
	minHeight: 'calc(63vh)',
	position: 'relative',
	textAlign: 'center',
	width: '60rem',
	zIndex: 1,

	variants: {
		scale: {
			desktop: {
				display: 'block',
				left: '50%',
				margin: '0 auto',
				maxWidth: '85%',
				minHeight: 0,
				padding: 0,
				position: 'absolute',
				top: '50%',
				transform: 'translate3d(-50%, -50%, 0)',
				width: 950,
				zIndex: 3,
			},
			mobile: {
				height: '100%',
				minHeight: 'calc(40vh)',
				padding: '14rem 1rem 10rem',
			},
			large: {
				maxWidth: '95vw',
				width: '100%',
			},
		},
	},
});

export const Title = styled('div', {
	position: 'relative',
});

export const Heading = styled('h1', {
	color: '$bronze',
	fontFamily: '$baskerville',
	fontSize: 'clamp(6rem, 9vw + 1rem, 15rem)',
	lineHeight: 1,
	textTransform: 'uppercase',
	fontWeight: '$reg',
	position: 'relative',
});
