import classnames from 'classnames';
import { Content } from 'components/shared/Content';
import { useScrollSpy } from 'hooks/useScrollSpy';
import { useScrollTo } from 'hooks/useScrollTo';
import { useState } from 'react';
import { INavItem, IRef } from 'types';

import {
	Anchor,
	Burger,
	Header,
	List,
	ListItem,
	Logo,
	Nav,
	Path,
	Svg,
} from './styles';

interface INavbarProps {
	pages: INavItem[];
	refs: IRef[];
}

export const Navbar = ({ pages, refs }: INavbarProps) => {
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const { activePage, showBackground } = useScrollSpy(refs);
	const { scrollTo } = useScrollTo();

	const onNavClick = (page: string) => {
		setShowMobileMenu(false);
		scrollTo(page);
	};

	return (
		<Header
			className={classnames({
				cover: showMobileMenu,
				shady: showBackground,
			})}
			role="banner"
			scale={{ '@initial': 'mobile', '@nav': 'desktop' }}
		>
			<Content>
				<Logo
					onClick={() => onNavClick('hero')}
					scale={{ '@initial': 'mobile', '@nav': 'desktop' }}
				/>

				<Nav
					className={classnames({ center: showMobileMenu })}
					role="navigation"
					scale={{ '@initial': 'mobile', '@nav': 'desktop' }}
				>
					<List
						className={classnames({ visible: showMobileMenu })}
						scale={{ '@initial': 'mobile', '@nav': 'desktop' }}
					>
						{pages.map((page) => (
							<ListItem
								className={classnames({ active: activePage === page.slug })}
								key={page.slug}
							>
								<Anchor
									onClick={() => onNavClick(page.slug)}
									scale={{ '@initial': 'mobile', '@nav': 'desktop' }}
								>
									{page.nav}
								</Anchor>
							</ListItem>
						))}
					</List>
				</Nav>

				<Burger
					aria-label="navigation"
					onClick={() => setShowMobileMenu(!showMobileMenu)}
					scale={{ '@initial': 'mobile', '@nav': 'desktop' }}
				>
					<Svg viewBox="0 0 10 10">
						<Path
							className={classnames('p1', { rotate: showMobileMenu })}
							d="M8,3 L2,3"
						/>
						<Path
							className={classnames('p2', { rotate: showMobileMenu })}
							d="M2,5 L8,5"
						/>
						<Path
							className={classnames('p3', { rotate: showMobileMenu })}
							d="M8,7 L2,7"
						/>
					</Svg>
				</Burger>
			</Content>
		</Header>
	);
};
