import { Content } from 'components/shared/Content';
import { Divider } from 'components/shared/Divider';
import React, { forwardRef } from 'react';
import { Header, Section } from 'styles/layout';
import { Heading } from 'styles/typography';
import { IPage } from 'types';

import { Article } from './styles';

interface IInfoProps {
	page: IPage;
}

export const Info = forwardRef<HTMLDivElement, IInfoProps>(({ page }, ref) => (
	<Section id="info" ref={ref}>
		<Content center>
			<Header>
				<Heading>{page.heading}</Heading>
			</Header>
			<Article dangerouslySetInnerHTML={{ __html: page.content }} />
		</Content>
		<Divider />
	</Section>
));

Info.displayName = 'Info';
