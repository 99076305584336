import naturalCompare from 'natural-compare-lite';

/**
 * .sort() compatible function with natural compare
 * (e.g. 'foo 10' comes after 'foo 9') for objects.
 * @param property Pass in the property to sort by.
 */
export const pureSort = (p: string, sensitive?: boolean) => {
	return (
		a: any, // eslint-disable-line @typescript-eslint/no-explicit-any
		b: any, // eslint-disable-line @typescript-eslint/no-explicit-any
	) => {
		const av = a[p];
		const bv = b[p];

		if (typeof av === 'string' && typeof bv === 'string') {
			if (sensitive) {
				return naturalCompare(av, bv);
			}
			return naturalCompare(av.toLowerCase(), bv.toLowerCase());
		}

		if (av > bv) return 1;
		if (av < bv) return -1;
		return 0;
	};
};
