import { styled } from 'styles/stitches.config';

interface IContentProps {
	children: React.ReactNode;
	center?: boolean;
	noPadding?: boolean;
}

const Box = styled('div', {
	height: '100%',
	position: 'relative',
});

export const Content = ({ children, center, noPadding }: IContentProps) => (
	<Box
		css={{
			margin: center ? 'auto' : 0,
			maxWidth: center ? '120rem' : '100%',
			padding: noPadding ? 0 : '$x2',
		}}
	>
		{children}
	</Box>
);
