import { styled } from 'styles/stitches.config';

export const Container = styled('footer', {
	backgroundColor: '$black',
	color: '$white',
	textAlign: 'center',
	padding: '5rem 0',

	'& a': {
		color: '$bronze',
		textDecoration: 'none',

		'&:hover': {
			textDecoration: 'underline',
		},
	},
});

export const Logo = styled('div', {
	margin: '0 auto 3rem',
	width: '15rem',
});

export const LinkedList = styled('ul', {
	display: 'flex',
	margin: '1.75rem auto',
	maxWidth: '20rem',
	justifyContent: 'space-between',
});

export const Anchor = styled('a', {
	display: 'flex',
	fill: '$white',
	transition: 'fill 0.25s ease-in-out',

	'&:hover': {
		fill: '$bronze',
	},
});
