import { Divider } from 'components/shared/Divider';
import { forwardRef } from 'react';
import { Section } from 'styles/layout';
import { IHero } from 'types';

import { Container, Content, Heading, Title, Video } from './styles';

interface IHeroProps {
	page: IHero;
}

export const Hero = forwardRef<HTMLDivElement, IHeroProps>(({ page }, ref) => (
	<Section id="hero" ref={ref}>
		<Container size={{ '@initial': 'desktop', '@portrait': 'mobile' }}>
			<Video
				autoPlay
				loop
				muted
				playsInline
				size={{ '@initial': 'desktop', '@portrait': 'mobile' }}
			>
				<source src={page.banner.webm} />
				<source src={page.banner.mp4} />
			</Video>
			<Content
				scale={{
					'@initial': 'desktop',
					'@landscape': 'desktop',
					'@portrait': 'mobile',
					'@bp4': 'large',
				}}
			>
				<Title>
					<Heading>{page.heading}</Heading>
				</Title>
			</Content>
		</Container>
		<Divider />
	</Section>
));

Hero.displayName = 'Hero';
