import { styled } from 'styles/stitches.config';

import GoldenEye from '../../public/images/goldeneye.png';
import Image from './Image';

export const Divider = () => (
	<Break>
		<Logo>
			<Image alt="Vaino Music" layout="responsive" src={GoldenEye} />
		</Logo>
	</Break>
);

const Break = styled('div', {
	display: 'flex',
	margin: '3rem auto',
	maxWidth: '10rem',
	justifyContent: 'center',
	alignItems: 'center',
	opacity: 0.25,
	position: 'relative',

	'&::before': {
		content: '',
		position: 'absolute',
		backgroundColor: '$bronze',
		height: 1,
		width: '2.5rem',
		left: 0,
	},

	'&::after': {
		content: '',
		position: 'absolute',
		backgroundColor: '$bronze',
		height: 1,
		width: '2.5rem',
		right: 0,
	},
});

const Logo = styled('div', {
	width: '3.5rem',
});
