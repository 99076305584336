import { useRef } from 'react';
import { ISlug } from 'types';

export const useRefs = () => {
	const artistsRef = useRef(null);
	const contactRef = useRef(null);
	const heroRef = useRef(null);
	const infoRef = useRef(null);
	const storeRef = useRef(null);
	return {
		getRef: (id: ISlug) => {
			if (id === 'artists') return artistsRef;
			if (id === 'contact') return contactRef;
			if (id === 'hero') return heroRef;
			if (id === 'info') return infoRef;
			if (id === 'store') return storeRef;
			return null;
		},
	};
};
