import { styled } from 'styles/stitches.config';

export const Article = styled('article', {
	textAlign: 'center',

	'& a': {
		color: '$bronze',
		textDecoration: 'none',

		'&:hover': {
			textDecoration: 'underline',
		},
	},
});
