import Head from 'next/head';
import { IMetaTags } from 'types';

interface IMetaProps {
	meta: IMetaTags;
}

export const Meta = ({ meta }: IMetaProps) => {
	const { facebook, page, twitter } = meta;
	return (
		<Head>
			<title>{page.title}</title>
			<meta content={page.description} name="description" />
			<link href={page.canonical} rel="canonical" />

			<meta content={facebook.locale} property="og:locale" />
			<meta content={facebook.type} property="og:type" />
			<meta content={facebook.title} property="og:title" />
			<meta content={facebook.description} property="og:description" />
			<meta content={facebook.url} property="og:url" />

			<meta content={twitter.card} name="twitter:card" />
			<meta content={twitter.description} name="twitter:description" />
			<meta content={twitter.title} name="twitter:title" />
			<meta content={twitter.image} name="twitter:image" />
		</Head>
	);
};
