import { Artists } from 'components/sections/Artists';
import { Footer } from 'components/sections/Footer';
import { Hero } from 'components/sections/Hero';
import { Info } from 'components/sections/Info';
import { Meta } from 'components/sections/Meta';
import { Navbar } from 'components/sections/Navbar';
import { Store } from 'components/sections/Store';
import { useRefs } from 'hooks/useRefs';
import type { NextPage } from 'next';
import { IArtist, IContact, IHero, IMetaTags, IPage } from 'types';
import { pureSort } from 'utils/pureSort';

const API_URL = 'https://api.vainomusic.com/wp-json/api/v1/data';

export async function getStaticProps() {
	const data = await fetch(API_URL);
	return { props: { data: await data.json() } };
}

interface IResponse {
	artists: IArtist[];
	hero: IHero;
	meta: IMetaTags;
	pages: IPage[];
	contact: IContact;
}

interface IPageProps {
	data: IResponse;
}

interface IPageRef {
	section: string;
	ref: React.RefObject<HTMLElement>;
}

const Index: NextPage<IPageProps> = ({ data }) => {
	const { getRef } = useRefs();

	const pages = data.pages.sort(pureSort('weight'));
	const refs: IPageRef[] = pages.map((page) => {
		const ref = getRef(page.slug);
		if (!ref) {
			throw new Error('Cannot initialize pages without ref!');
		}
		return { ref, section: page.slug };
	});

	return (
		<>
			<Meta meta={data.meta} />
			<main id="main" role="main">
				<Navbar
					pages={pages.map(({ nav, slug }) => ({ nav, slug }))}
					refs={refs}
				/>
				{pages.map((page) => {
					if (page.slug === 'hero') {
						return (
							<Hero key={page.slug} page={data.hero} ref={getRef('hero')} />
						);
					}
					if (page.slug === 'info') {
						return <Info key={page.slug} page={page} ref={getRef('info')} />;
					}
					if (page.slug === 'artists') {
						return (
							<Artists
								artists={data.artists}
								key={page.slug}
								ref={getRef('artists')}
							/>
						);
					}
					if (page.slug === 'store') {
						return <Store key={page.slug} page={page} ref={getRef('store')} />;
					}
					if (page.slug === 'contact') {
						return (
							<Footer
								contact={data.contact}
								key={page.slug}
								ref={getRef('contact')}
							/>
						);
					}
				})}
			</main>
		</>
	);
};

export default Index;
