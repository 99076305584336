import { styled } from 'styles/stitches.config';

export const Grid = styled('div', {
	alignItems: 'center',
	display: 'grid',
	gap: '1rem',
	gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
});

export const Article = styled('article', {
	alignItems: 'center',
	aspectRatio: '1/1',
	display: 'flex',
	justifyContent: 'center',
	objectFit: 'contain',
	position: 'relative',
});

export const Picture = styled('div', {
	filter: 'grayscale(1)',
	height: '100%',
	width: '100%',
});

export const Title = styled('div', {
	alignItems: 'center',
	border: '4px solid $bronze',
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	opacity: 0,
	position: 'absolute',
	width: '100%',

	transition: 'opacity 0.25s ease-in-out',

	'&:hover': {
		opacity: 1,
	},

	variants: {
		responsive: {
			desktop: {
				opacity: 0,
				backgroundColor: '$ox2',
				justifyContent: 'center',
			},
			mobile: {
				opacity: 1,
				backgroundColor: 'transparent',
				justifyContent: 'space-between',
				paddingBottom: '2rem',
				paddingTop: '2rem',
			},
		},
	},
});

export const Name = styled('h2', {
	color: '$white',
});

export const SocialList = styled('ul', {
	display: 'flex',
	marginTop: '$x1',
});

export const Anchor = styled('a', {
	fill: '$white',
	padding: '0.7rem',
	transition: 'fill 0.25s ease-in-out',

	'&:hover': {
		fill: '$bronze',
	},
});
