import { Content } from 'components/shared/Content';
import { Divider } from 'components/shared/Divider';
import { forwardRef } from 'react';
import { Header, Section } from 'styles/layout';
import { Heading } from 'styles/typography';
import { IPage } from 'types';

import { Article, Button } from './styles';

interface IStoreProps {
	page: IPage;
}

export const Store = forwardRef<HTMLDivElement, IStoreProps>(
	({ page }, ref) => (
		<Section id="store" ref={ref}>
			<Content center>
				<Header>
					<Heading>{page.heading}</Heading>
				</Header>
				<Article>
					<div dangerouslySetInnerHTML={{ __html: page.content }} />
					{page.action && (
						<Button
							href={page.action.address}
							rel="noopener noreferrer"
							target="_blank"
						>
							{page.action.text}
						</Button>
					)}
				</Article>
			</Content>
			<Divider />
		</Section>
	),
);

Store.displayName = 'Store';
