import { styled } from 'styles/stitches.config';

export const Header = styled('header', {
	height: '9rem',
	position: 'fixed',
	transition: 'background 0.45s',
	width: '100%',
	zIndex: 999,

	variants: {
		scale: {
			desktop: {
				'&.cover': {
					height: '9rem',
					background: 'transparent',
				},
				'&.shady': {
					background: 'rgba(0, 0, 0, .95)',
				},
			},
			mobile: {
				'&.cover': {
					height: '100%',
					background: 'rgba(0, 0, 0, .95)',
				},
				'&.shady': {
					background: 'rgba(0, 0, 0, .95)',
				},
			},
		},
	},
});

export const Logo = styled('div', {
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100%',
	cursor: 'pointer',
	left: '3rem',
	position: 'absolute',
	top: '3rem',

	variants: {
		scale: {
			desktop: {
				backgroundImage: 'url("/images/text.png")',
				height: '3rem',
				width: '15rem',
			},
			mobile: {
				backgroundImage: 'url("/images/goldeneye.png")',
				height: '3rem',
				width: '3rem',
			},
		},
	},
});

export const Burger = styled('button', {
	$$button: '5rem',

	backgroundColor: 'transparent',
	border: 0,
	cursor: 'pointer',
	height: '$$button',
	padding: '$x1',
	width: '$$button',
	position: 'absolute',
	right: '3rem',

	variants: {
		scale: {
			desktop: {
				display: 'none',
			},
			mobile: {
				display: 'block',
			},
		},
	},
});

export const Svg = styled('svg', {
	cursor: 'pointer',
});

export const Path = styled('path', {
	fill: 'none',
	stroke: '$white',
	strokeWidth: '.5px',
	transformOrigin: 'center',
	transition: '.2s',

	'&.p1.rotate': {
		d: 'path("M2,8 L8,2")',
	},

	'&.p2.rotate': {
		d: 'path("M5,5 L5,5")',
	},

	'&.p3.rotate': {
		d: "path('M2,2 L8,8')",
	},
});

export const Nav = styled('nav', {
	variants: {
		scale: {
			desktop: {
				top: '1.75rem',
				position: 'absolute',
				right: '3rem',
				'&.center': {
					top: '1.75rem',
					position: 'absolute',
				},
			},
			mobile: {
				'&.center': {
					top: 0,
					position: 'relative',
				},
			},
		},
	},
});

export const List = styled('ul', {
	$$listHeight: '85px',

	textAlign: 'center',
	width: '100%',

	variants: {
		scale: {
			desktop: {
				display: 'flex',
				flexDirection: 'row',
				height: 'auto',
				position: 'relative',
				top: 0,

				'&.visible': {
					display: 'flex',
				},
			},
			mobile: {
				display: 'none',
				flexDirection: 'column',
				height: 'calc(100vh -  $$listHeight)',
				left: 0,
				position: 'absolute',
				top: '$$listHeight',

				'&.visible': {
					display: 'block',
				},
			},
		},
	},
});

export const ListItem = styled('li', {
	'&.active span': {
		color: '$bronze',
	},
});

export const Anchor = styled('span', {
	color: '$white',
	cursor: 'pointer',
	display: 'block',
	fontFamily: '$amiko',
	padding: '$x2',
	textTransform: 'uppercase',

	'&:hover': {
		color: '$bronze',
		textDecoration: 'underline',
	},

	variants: {
		noPadding: {
			true: {
				padding: 0,
			},
		},
		scale: {
			desktop: {
				fontSize: '$x1',
			},
			mobile: {
				fontSize: '$x2',
			},
		},
	},
});
