import { styled } from 'styles/stitches.config';

export const Article = styled('article', {
	textAlign: 'center',

	'& a': {
		marginTop: '$x3',
	},
});

export const Button = styled('a', {
	backgroundColor: '$bronze',
	color: '$white',
	display: 'inline-block',
	padding: '1.2rem 3rem',
	textDecoration: 'none',
	textTransform: 'uppercase',
	letterSpacing: '2px',
	border: '4px solid transparent',
	fontSize: '1.6rem',

	transition: 'all 0.25s ease-in-out',

	'&:hover': {
		backgroundColor: 'transparent',
		color: '$bronze',
		borderColor: '$bronze',
		textDecoration: 'none',
	},
});
