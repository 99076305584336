import { Content } from 'components/shared/Content';
import { Icon } from 'components/shared/Icons';
import Image from 'components/shared/Image';
import { forwardRef } from 'react';
import { Email } from 'react-obfuscate-email';
import { Section } from 'styles/layout';
import { IContact } from 'types';

import GoldenEye from '../../../public/images/goldeneye.png';
import { Anchor, Container, LinkedList, Logo } from './styles';

interface IFooterProps {
	contact: IContact;
}

export const Footer = forwardRef<HTMLDivElement, IFooterProps>(
	({ contact }, ref) => (
		<Container css={{ marginTop: '5rem' }} id="contact" ref={ref}>
			<Section>
				<Content center>
					<Logo>
						<Image alt="Vaino Music" layout="responsive" src={GoldenEye} />
					</Logo>
					<ul>
						<li>{contact.info.address}</li>
						<li>{contact.info.bid}</li>
						<li>
							<Email email={contact.info.email} />
						</li>
						<li>
							<LinkedList>
								{contact.social.map((some) => (
									<li key={some.channel}>
										<Anchor
											href={some.href}
											rel="noopener noreferrer"
											target="_blank"
											title={some.channel.replace(/\b[a-zA-Z]/g, (match) =>
												match.toUpperCase(),
											)}
										>
											<Icon channel={some.channel} />
										</Anchor>
									</li>
								))}
							</LinkedList>
						</li>
						<li>
							Vaino Music Oy &copy; {new Date().getFullYear()} | All rights
							reserved.
						</li>
					</ul>
				</Content>
			</Section>
		</Container>
	),
);

Footer.displayName = 'Footer';
