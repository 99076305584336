import { useEffect, useState } from 'react';
import { IRef } from 'types';

export const useScrollSpy = (refs: IRef[]) => {
	const [activePage, setActivePage] = useState<string>();
	const [showBackground, setShowBackground] = useState(false);

	const getDimensions = (el: HTMLElement) => {
		const { height } = el.getBoundingClientRect();
		const offsetTop = el.offsetTop;
		const offsetBottom = offsetTop + height;
		return { height, offsetTop, offsetBottom };
	};

	useEffect(() => {
		const watcher = () => {
			const scrollPosition = window.scrollY + 100;

			setShowBackground(scrollPosition > 100);

			if (window.innerHeight + scrollPosition >= document.body.offsetHeight) {
				setActivePage('contact');
				return;
			}

			const selected = refs.find(({ ref }) => {
				const el = ref.current;
				if (el) {
					const { offsetBottom, offsetTop } = getDimensions(el);
					return scrollPosition > offsetTop && scrollPosition < offsetBottom;
				}
			});

			if (selected && selected.section !== activePage) {
				setActivePage(selected.section);
			} else if (!selected && activePage) {
				setActivePage(undefined);
			}
		};

		watcher();

		window.addEventListener('scroll', watcher);
		return () => {
			window.removeEventListener('scroll', watcher);
		};
	}, [activePage, refs]);

	return {
		activePage,
		showBackground,
	};
};
