import { createStitches } from '@stitches/react';

import { colors } from './colors';
import { fonts } from './fonts';
import { reset } from './reset';

export const { styled, keyframes, globalCss, getCssText } = createStitches({
	theme: {
		colors: {
			...colors,
			// Semi-solid overlays
			ox1: 'rgba(0, 0, 0, 0.45)',
			ox2: 'rgba(0, 0, 0, 0.7)',
		},
		fonts: {
			...fonts,
			system: 'system-ui',
		},
		fontWeights: {
			reg: 400,
		},
		// Base: 16px
		fontSizes: {
			x1: '1.6rem',
			x2: '2.4rem',
			x3: '3.2rem',
			x4: '4rem',

			base: 'clamp(1.6rem, 12vw + 1rem, 1.8rem)',
			heading: 'clamp(4rem, 5vw + 1rem, 5rem)',
		},
		space: {
			x1: '1rem',
			x2: '2rem',
			x3: '3rem',
			x4: '4rem',
		},
		sizes: {
			max: '120rem',
		},
	},
	media: {
		// Small devices (landscape phones, 576px and up)
		bp1: '(min-width: 576px)',
		// Medium devices (tablets, 768px and up)
		bp2: '(min-width: 768px)',
		// Large devices (desktops, 992px and up)
		bp3: '(min-width: 992px)',
		// Extra large devices (large desktops, 1200px and up)
		bp4: '(min-width: 1200px)',

		// Extra extra large
		bb1: '(min-width: 1340px)',

		// Orientations
		portrait: '(orientation: portrait)',
		landscape: '(orientation: landscape)',

		// Component specific breakpoints

		// Nav
		nav: '(min-width: 910px)',

		// Hero title
		hb1: '(min-width: 870px)',
	},
	prefix: 'vaino',
});

const layout = {
	body: {
		color: '$black',
		fontSize: '$base',
		fontFamily: '$arvo',
		lineHeight: 1.6,
	},
	a: {
		color: '$bronze',
		cursor: 'pointer',
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
};

globalCss({ ...reset, ...layout })();
