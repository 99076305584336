export const reset = {
	'*, *::before, *::after': {
		margin: 0,
		padding: 0,
		boxSizing: 'inherit',
		'-moz-osx-font-smoothing': 'grayscale',
		'-webkit-font-smoothing': 'antialiased',
		'-webkit-tap-highlight-color': 'transparent',
	},

	html: {
		boxSizing: 'border-box',
		fontFamily: 'sans-serif',
		fontSize: '62.5%',
		scrollBehavior: 'smooth',
	},

	/* Set core body defaults */
	body: {
		minHeight: '100vh',
		textRendering: 'optimizeSpeed',
	},

	'*[hidden]': {
		display: 'none',
	},

	/* Remove list styles on ul, ol elements with a class attribute */
	'ul, ol': {
		listStyle: 'none',
	},

	/* A elements that don't have a class get default styles */
	'a:not([class])': {
		'text-decoration-skip-ink': 'auto',
	},

	/* Inherit fonts for inputs and buttons */
	'input, button, textarea, select': {
		font: 'inherit',
	},

	p: {
		marginBottom: '1rem',
	},

	/* Remove all animations and transitions for people that prefer not to see them */
	'@media (prefers-reduced-motion: reduce)': {
		'& *': {
			animationDuration: '0.01ms !important',
			animationIterationCount: '1 !important',
			transitionDuration: '0.01ms !important',
			scrollBehavior: 'auto !important',
		},
	},
};
